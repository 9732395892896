import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf } from '@angular/common';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Observable, Observer, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { BaseField } from '../base-field';
import { ValidationErrorsComponent } from 'shared/components/forms/validation-errors/validation-errors.component';

@Component({
  selector: 'typeahead-field',
  templateUrl: './typeahead-field.component.html',
  styleUrls: ['./typeahead-field.component.scss'],
  imports: [ReactiveFormsModule, TypeaheadModule, NgIf, ValidationErrorsComponent, FaIconComponent],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TypeaheadFieldComponent), multi: true }]
})
export class TypeaheadFieldComponent extends BaseField implements OnInit {

  @Input({ required: true }) search: (formControl: FormControl) => Observable<any[]>;
  @Input({ required: true }) labelProp: string;
  @Input({ required: true }) valueProp: string;
  @Input() type: 'string' | 'number' = 'number';
  @Input() icon!: IconName;
  @Input() translateInitialValueToLabel!: (valueProp: any) => Observable<any>;
  @Input() optionsLimit = 10;
  @Input() minLength = 0;
  @Input() selectFirstItem = true;
  @Input() isFirstItemActive = true;
  @Input() debounceTime = 100;
  @Input() description!: string;

  @Output() onSelect = new EventEmitter<any>();

  typeaheadControl = new FormControl<string>('');
  dataSource: Observable<any>;
  value: string | number;

  onChanged: any = () => { /* noop */ }
  onTouched: any = () => { /* noop */ }

  private noResult = false;
  private initialValue: string | number;

  ngOnInit() {
    super.ngOnInit();
    this.initLabelProp();
    this.initialValue = this.control?.value;
    this.initTypeaheadFieldChangeListener();
    this.dataSource = new Observable((observer: Observer<any>) => {
      observer.next(this.typeaheadControl);
    }).pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      filter(term => term !== null),
      switchMap(this.search || this.showMissingConfiguration)
    );
  }

  writeValue(value: string | number) {
    this.value = !value && this.type === 'number' ? null : value;
  }

  registerOnChange(fn: any) {
    this.onChanged = fn
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn
  }

  showMissingConfiguration() {
    return new Observable((observer: Observer<any[]>) => {
      observer.next(['Please configure a search function as component input!']);
    });
  }

  selectItem(event: TypeaheadMatch) {
    const value = event.item[this.valueProp] ?? (this.type === 'number' ? null : '');
    this.setValue(value);
  }

  onBlur() {
    if (this.noResult) {
      const value = this.typeaheadControl.value ? this.initialValue : this.control.value;
      this.typeaheadControl.setValue(value ? String(value) : value, { emitModelToViewChange: true, emitEvent: false });
      this.setValue(value);
    }
  }

  typeaheadNoResult(noResult: boolean) {
    this.noResult = noResult;
  }

  private setValue(value: string | number) {
    this.onChanged(value);
    this.onTouched();
    this.onSelect.emit(value);
    this.control.setValue(value, { emitModelToViewChange: true, emitEvent: false })
  }

  private initLabelProp() {
    if (this.translateInitialValueToLabel && this.control.value) {
      const translate = this.translateInitialValueToLabel(this.control.value);
      translate
        .pipe(
          map(model => model ? model[this.labelProp] : this.control.value),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(label => this.typeaheadControl.setValue(label));
    }
  }

  private initTypeaheadFieldChangeListener() {
    this.typeaheadControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (!value) {
          this.onChanged(this.type === 'number' ? null : '');
          this.onTouched();
          this.onSelect.emit(this.type === 'number' ? null : '');
          this.control.setValue(this.type === 'number' ? null : '', { emitModelToViewChange: true, emitEvent: false })
        }
      });
  }
}
